import { Card } from '@/components/ui/card';
import { Badge } from '@/components/ui/badge';
import { Building2, Briefcase, Code2, Rocket } from 'lucide-react';

const experiences = [
  {
    icon: Building2,
    title: 'Senior Business Process Specialist',
    company: 'COSCO Shipping Lines',
    location: 'Malaysia',
    period: '2023 - 2024',
    description: [
      'Led the design and implementation of RPA solutions that reduced manual processing time by 75%',
      'Enhanced interdepartmental processes through automation, saving 200+ work hours monthly',
      'Developed and conducted comprehensive RPA training programs for 50+ non-technical staff',
      'Implemented quality control measures resulting in 99.9% automation accuracy'
    ],
    tags: ['RPA', 'Process Automation', 'Training', 'UiPath', 'Workflow Optimization']
  },
  {
    icon: Rocket,
    title: 'Co-Founder & CTO',
    company: 'Frenbase',
    location: 'Malaysia',
    period: '2022 - 2023',
    description: [
      'Architected and built scalable e-commerce platforms serving 10,000+ monthly active users',
      'Developed RESTful APIs handling 1M+ requests monthly with 99.9% uptime',
      'Implemented real-time inventory management system reducing stockouts by 40%',
      'Led a team of 5 developers and managed the entire technical infrastructure'
    ],
    tags: ['E-commerce', 'API Development', 'Architecture', 'Team Leadership', 'AWS', 'Node.js']
  },
  {
    icon: Briefcase,
    title: 'Founder',
    company: 'Kamfu Systems',
    location: 'Malaysia',
    period: '2017 - 2019',
    description: [
      'Pioneered QR code-based agricultural product tracking system used by 50+ farms',
      'Developed sports facility booking platform handling 1000+ monthly bookings',
      'Created membership management system for sports clubs with 5000+ active users',
      'Secured and managed relationships with key stakeholders and government agencies'
    ],
    tags: ['QR Tracking', 'Booking Systems', 'Product Development', 'Entrepreneurship', 'React', 'MongoDB']
  },
  {
    icon: Code2,
    title: 'Senior Software Engineer',
    company: 'Innity',
    location: 'Malaysia',
    period: '2011 - 2017, 2019 - 2022',
    description: [
      'Architected and built high-performance APIs handling 10M+ daily requests',
      'Established automated CI/CD pipelines reducing deployment time by 70%',
      'Developed innovative ad formats increasing client engagement rates by 300%',
      'Mentored junior developers and led technical knowledge sharing sessions'
    ],
    tags: ['API Development', 'CI/CD', 'Ad Tech', 'Mentoring', 'Docker', 'Kubernetes']
  }
];

export function Experience() {
  return (
    <section id="experience" className="py-20 scroll-mt-20">
      <div className="text-center mb-12">
        <h2 className="text-3xl font-bold tracking-tight mb-4">Professional Journey</h2>
        <p className="text-muted-foreground max-w-[700px] mx-auto">
          Over 15 years of experience spanning software development, entrepreneurship, and business innovation. 
          Specialized in building scalable solutions and driving digital transformation across industries.
        </p>
      </div>
      <div className="space-y-6 max-w-4xl mx-auto">
        {experiences.map((exp, index) => (
          <Card key={index} className="p-6 hover:shadow-lg transition-shadow">
            <div className="flex gap-4">
              <div className="hidden sm:flex items-start pt-1">
                <exp.icon className="h-6 w-6 text-primary" />
              </div>
              <div className="flex-1">
                <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-2 mb-4">
                  <div>
                    <h3 className="font-semibold text-xl">{exp.title}</h3>
                    <div className="flex items-center gap-2">
                      <p className="text-muted-foreground">{exp.company}</p>
                      <span className="text-muted-foreground">•</span>
                      <p className="text-muted-foreground text-sm">{exp.location}</p>
                    </div>
                  </div>
                  <Badge variant="secondary" className="whitespace-nowrap">
                    {exp.period}
                  </Badge>
                </div>
                <ul className="list-disc list-inside space-y-2 mb-4">
                  {exp.description.map((item, i) => (
                    <li key={i} className="text-muted-foreground">
                      {item}
                    </li>
                  ))}
                </ul>
                <div className="flex flex-wrap gap-2">
                  {exp.tags.map((tag, i) => (
                    <Badge key={i} variant="outline" className="text-sm">
                      {tag}
                    </Badge>
                  ))}
                </div>
              </div>
            </div>
          </Card>
        ))}
      </div>
    </section>
  );
}