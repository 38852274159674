import { Button } from '@/components/ui/button';
import { Github, Linkedin, Mail } from 'lucide-react';

export function Footer() {
  return (
    <footer>
      <div className="container py-6 flex justify-between items-center">
        <p className="text-sm text-muted-foreground">© 2024 TH. All rights reserved.</p>
        <div className="flex gap-4">
          <Button variant="ghost" size="icon" asChild>
            <a href="https://github.com/tanghoong" target="_blank" rel="noopener noreferrer">
              <Github className="h-4 w-4" />
            </a>
          </Button>
          <Button variant="ghost" size="icon" asChild>
            <a href="https://www.linkedin.com/in/tanghoong/" target="_blank" rel="noopener noreferrer">
              <Linkedin className="h-4 w-4" />
            </a>
          </Button>
          <Button variant="ghost" size="icon" asChild>
            <a href="mailto:career@tanghoong.com">
              <Mail className="h-4 w-4" />
            </a>
          </Button>
        </div>
      </div>
    </footer>
  );
}