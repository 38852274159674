import { Routes, Route } from 'react-router-dom';
import { HomePage } from '@/pages/home';
import { AboutPage } from '@/pages/about';
import { ServicesPage } from '@/pages/services';
import { ExperiencePage } from '@/pages/experience';
import { SkillsPage } from '@/pages/skills';
import { ProjectsPage } from '@/pages/projects';
import { CertificationsPage } from '@/pages/certifications';
import { ContactPage } from '@/pages/contact';
import { NotFoundPage } from '@/pages/not-found';

export function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/about" element={<AboutPage />} />
      <Route path="/services" element={<ServicesPage />} />
      <Route path="/experience" element={<ExperiencePage />} />
      <Route path="/skills" element={<SkillsPage />} />
      <Route path="/projects" element={<ProjectsPage />} />
      <Route path="/certifications" element={<CertificationsPage />} />
      <Route path="/contact" element={<ContactPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}