import { Card } from '@/components/ui/card';
import { Badge } from '@/components/ui/badge';
import { Code2, Cloud, Cpu, Palette, Database, Lock, Globe, Terminal } from 'lucide-react';

const skillCategories = [
  {
    icon: Code2,
    title: 'Programming Languages',
    description: 'Core languages I use to build robust applications',
    skills: ['Python', 'PHP', 'JavaScript', 'TypeScript', 'Node.js', 'HTML/CSS', 'SQL']
  },
  {
    icon: Cpu,
    title: 'Frameworks & Libraries',
    description: 'Modern tools I leverage for efficient development',
    skills: ['React', 'Next.js', 'Laravel', 'Django', 'Flask', 'FastAPI', 'Express.js', 'Vue.js']
  },
  {
    icon: Cloud,
    title: 'Cloud & DevOps',
    description: 'Infrastructure and deployment expertise',
    skills: ['Google Cloud', 'AWS', 'DigitalOcean', 'Cloudflare', 'Docker', 'Kubernetes', 'CI/CD', 'Linux', 'Nginx']
  },
  {
    icon: Database,
    title: 'Databases & Storage',
    description: 'Data management solutions I work with',
    skills: ['PostgreSQL', 'MySQL', 'MongoDB', 'Redis', 'Firebase', 'ElasticSearch']
  },
  {
    icon: Lock,
    title: 'Security & Testing',
    description: 'Ensuring application reliability and safety',
    skills: ['OAuth', 'JWT', 'Jest', 'PyTest', 'Cypress', 'Security Best Practices']
  },
  {
    icon: Palette,
    title: 'Design & Tools',
    description: 'Creating seamless user experiences',
    skills: ['Wireframing', 'Figma', 'Tailwind CSS', 'Material UI', 'RPA', 'Selenium', 'Adobe XD']
  },
  {
    icon: Globe,
    title: 'APIs & Integration',
    description: 'Connecting systems and services',
    skills: ['RESTful APIs', 'GraphQL', 'WebSockets', 'OAuth2', 'Payment Gateways', 'Third-party APIs']
  },
  {
    icon: Terminal,
    title: 'Development Tools',
    description: 'Tools that enhance my workflow',
    skills: ['Git', 'VS Code', 'Postman', 'Docker', 'Terminal', 'Webpack', 'npm/yarn']
  }
];

export function Skills() {
  return (
    <section id="skills" className="py-20 scroll-mt-20">
      <div className="text-center mb-12">
        <h2 className="text-3xl font-bold tracking-tight mb-4">Skills & Expertise</h2>
        <p className="text-muted-foreground max-w-[800px] mx-auto">
          A comprehensive toolkit built through years of hands-on experience in full-stack development,
          cloud infrastructure, and modern web technologies. Continuously learning and adapting to new technologies.
        </p>
      </div>
      <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
        {skillCategories.map((category, index) => (
          <Card key={index} className="p-6 hover:shadow-lg transition-shadow">
            <div className="flex items-center gap-3 mb-3">
              <category.icon className="h-6 w-6 text-primary" />
              <h3 className="font-semibold text-xl">{category.title}</h3>
            </div>
            <p className="text-muted-foreground text-sm mb-4">{category.description}</p>
            <div className="flex flex-wrap gap-2">
              {category.skills.map((skill, i) => (
                <Badge key={i} variant="secondary" className="hover:bg-primary hover:text-primary-foreground transition-colors">
                  {skill}
                </Badge>
              ))}
            </div>
          </Card>
        ))}
      </div>
    </section>
  );
}