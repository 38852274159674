import { Card } from '@/components/ui/card';
import { Award } from 'lucide-react';

const certifications = [
  {
    title: 'AI Supercharge Bootcamp',
    organization: 'UCAN Technologies',
    year: '2024'
  },
  {
    title: 'Crash Course on Python',
    organization: 'Coursera',
    year: '2023'
  },
  {
    title: 'Diploma in Network & Information Management',
    organization: 'Feng Chia University',
    year: '2010'
  }
];

export function Certifications() {
  return (
    <section id="certifications" className="py-20 scroll-mt-20">
      <div className="text-center mb-12">
        <h2 className="text-3xl font-bold tracking-tight mb-4">Certifications</h2>
        <p className="text-muted-foreground max-w-[700px] mx-auto">
          Continuous learning and professional development
        </p>
      </div>
      <div className="max-w-3xl mx-auto space-y-4">
        {certifications.map((cert, index) => (
          <Card key={index} className="p-6">
            <div className="flex items-start gap-4">
              <Award className="h-6 w-6 text-primary mt-1" />
              <div className="flex-1">
                <h3 className="font-semibold text-xl">{cert.title}</h3>
                <p className="text-muted-foreground">{cert.organization}</p>
                <p className="text-sm text-muted-foreground">{cert.year}</p>
              </div>
            </div>
          </Card>
        ))}
      </div>
    </section>
  );
}