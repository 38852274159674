import { Card } from '@/components/ui/card';
import { Code2, Bot, Workflow, Building2, Globe2, Cpu, Brain, Rocket } from 'lucide-react';

export function About() {
  const skills = [
    { 
      icon: Code2, 
      title: 'Full-Stack Development',
      description: 'Expert in React, Node.js, TypeScript and modern web technologies'
    },
    { 
      icon: Bot, 
      title: 'AI Solutions',
      description: 'Building intelligent systems with ML, NLP and computer vision'
    },
    { 
      icon: Workflow, 
      title: 'Automation',
      description: 'RPA, workflow optimization and process automation solutions'
    },
    { 
      icon: Building2, 
      title: 'SaaS Architecture',
      description: 'Scalable cloud-native architectures and microservices'
    },
  ];

  const highlights = [
    { icon: Globe2, text: '15+ years of global industry experience' },
    { icon: Cpu, text: 'Led 50+ successful enterprise projects' },
    { icon: Brain, text: 'Deep expertise in AI/ML implementation' },
    { icon: Rocket, text: 'Proven track record of scaling startups' }
  ];

  return (
    <section id="about" className="py-20 scroll-mt-20">
      <h2 className="text-3xl font-bold tracking-tight mb-8 text-center">About Me</h2>
      <div className="grid gap-8 md:grid-cols-2">
        <div className="space-y-6">
          <Card className="p-6">
            <p className="text-muted-foreground leading-relaxed">
              Hi, I'm Charlie Tang Hoong, a seasoned software engineer with 15 years of experience in crafting digital solutions. My career spans full-stack development, workflow automation, AI-driven applications, and scalable SaaS products. I thrive on turning complex challenges into streamlined, innovative solutions that drive business efficiency and growth.
            </p>
            <p className="mt-4 text-muted-foreground leading-relaxed">
              Currently based in Sarawak, Malaysia, I focus on leveraging emerging technologies like AI, RPA, and low-code platforms to empower businesses with smarter tools and scalable architectures. I've helped numerous organizations across Asia-Pacific transform their operations through technology.
            </p>
          </Card>
          <div className="grid grid-cols-2 gap-4">
            {highlights.map((highlight, index) => (
              <div key={index} className="flex items-center gap-2">
                <highlight.icon className="h-5 w-5 text-primary" />
                <span className="text-sm text-muted-foreground">{highlight.text}</span>
              </div>
            ))}
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4">
          {skills.map((skill, index) => (
            <Card key={index} className="p-6 flex flex-col items-center justify-center text-center hover:shadow-lg transition-shadow">
              <skill.icon className="h-8 w-8 mb-2 text-primary" />
              <h3 className="font-semibold mb-2">{skill.title}</h3>
              <p className="text-sm text-muted-foreground">{skill.description}</p>
            </Card>
          ))}
        </div>
      </div>
    </section>
  );
}