import { Card } from '@/components/ui/card';
import { Code2, Bot, ShoppingCart, Cpu, Cloud, Globe2, Database } from 'lucide-react';

const services = [
  {
    icon: Code2,
    title: 'Innovative Software Engineering',
    description: 'I specialize in full-stack development using modern tools like Python, React, and Tailwind CSS to create user-friendly, high-performance applications. From responsive web apps to complex enterprise systems, I deliver scalable solutions that meet business objectives.',
    technologies: ['React', 'Python', 'Node.js', 'TypeScript', 'Tailwind CSS']
  },
  {
    icon: Bot,
    title: 'Business Process Automation',
    description: "I've streamlined operations across industries by implementing Robotic Process Automation (RPA) solutions and training teams to build their own workflows. My automation solutions have helped companies reduce manual work by up to 70% and improve accuracy to 99.9%.",
    technologies: ['UiPath', 'Power Automate', 'Python Automation', 'Zapier']
  },
  {
    icon: ShoppingCart,
    title: 'E-commerce Solutions',
    description: 'From multi-vendor marketplaces to direct-to-consumer (DTC) platforms, I\'ve built scalable solutions that drive user engagement and sales. My e-commerce implementations have helped businesses achieve 40%+ growth in online revenue.',
    technologies: ['Shopify', 'WooCommerce', 'Custom E-commerce', 'Payment Gateways']
  },
  {
    icon: Cpu,
    title: 'AI & Machine Learning Integration',
    description: 'I leverage AI to enhance workflows, automate repetitive tasks, and create cutting-edge applications. From natural language processing to computer vision, I implement AI solutions that provide real business value.',
    technologies: ['TensorFlow', 'PyTorch', 'OpenAI API', 'Computer Vision']
  },
  {
    icon: Cloud,
    title: 'Cloud-Native SaaS Products',
    description: 'With a focus on API-first development, I design SaaS platforms for industries like advertising, e-commerce, and digital business tools. My cloud solutions are built for scalability, security, and optimal performance.',
    technologies: ['AWS', 'Azure', 'Docker', 'Kubernetes']
  },
  {
    icon: Database,
    title: 'Database Architecture',
    description: 'I design and implement robust database solutions that scale with your business. From traditional SQL to modern NoSQL systems, I ensure your data is organized, secure, and easily accessible.',
    technologies: ['PostgreSQL', 'MongoDB', 'Redis', 'ElasticSearch']
  },
  {
    icon: Globe2,
    title: 'API Development & Integration',
    description: 'I create robust, well-documented APIs that power modern applications. My expertise includes RESTful services, GraphQL, and microservices architecture for seamless system integration.',
    technologies: ['REST', 'GraphQL', 'Microservices', 'API Security']
  }
];

export function Services() {
  return (
    <section id="services" className="py-20 scroll-mt-20">
      <div className="text-center mb-12">
        <h2 className="text-3xl font-bold tracking-tight mb-4">Professional Services</h2>
        <p className="text-muted-foreground max-w-[700px] mx-auto">
          Transforming business challenges into elegant digital solutions through cutting-edge technology and industry best practices
        </p>
      </div>
      <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
        {services.map((service, index) => (
          <Card key={index} className="p-6 hover:shadow-lg transition-all duration-300 border-t-2 border-t-primary/20">
            <service.icon className="h-12 w-12 mb-4 text-primary" />
            <h3 className="font-semibold text-xl mb-2">{service.title}</h3>
            <p className="text-muted-foreground mb-4">{service.description}</p>
            <div className="flex flex-wrap gap-2">
              {service.technologies.map((tech, techIndex) => (
                <span 
                  key={techIndex}
                  className="text-xs px-2 py-1 bg-primary/10 text-primary rounded-full"
                >
                  {tech}
                </span>
              ))}
            </div>
          </Card>
        ))}
      </div>
    </section>
  );
}