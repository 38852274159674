import { useState, useEffect } from 'react';
import { Card } from '@/components/ui/card';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { Archive, ChevronDown, Globe, Search } from 'lucide-react';

type Project = {
  url: string;
  slogan: string;
  group: string;
  owner: string;
  status: string;
};

const projects: Project[] = [
  // Personal & Portfolio
  { url: 'TangHoong.com', status: 'Active', owner: 'Own', group: 'Personal Portfolio', slogan: 'A Professional Showcase of Digital Innovations and Expertise.' },
  { url: 'ZhengHo.org', status: 'Active', owner: 'Client', group: 'Personal Portfolio', slogan: 'Preserving Heritage and Legacy Through Digital Narratives.' },
  // Kamfu
  { url: 'Kamfu.net', status: 'Active', owner: 'Own', group: 'Technology & SaaS', slogan: 'Your Software Hub for Innovative Solutions Across .dev, .app, and .org.' },
  { url: 'Kamfu.org', status: 'Active', owner: 'Own', group: 'Technology & SaaS', slogan: 'Harnessing AI to Empower and Support Vulnerable Communities.' },
  { url: 'Kamfu.dev', status: 'Active', owner: 'Own', group: 'Technology & SaaS', slogan: 'Advanced Technology Solutions for Developers.' },
  { url: 'Kamfu.app', status: 'Active', owner: 'Own', group: 'Technology & SaaS', slogan: 'Streamlined Mobile Solutions for Digital Transformation.' },
  // Frenbase  
  { url: 'Frenbase.com', status: 'Active', owner: 'Own', group: 'Technology & SaaS', slogan: 'A Social and E-Commerce Platform Connecting Communities.' },
  { url: 'Frenbase.app', status: 'Active', owner: 'Own', group: 'Technology & SaaS', slogan: 'Seamlessly Connecting Communities with the Frenbase Mobile App.' },
  { url: 'Frenbase.net', status: 'Active', owner: 'Own', group: 'Technology & SaaS', slogan: 'A Social Network for Fashion Enthusiasts and Influencers.' },
  // E-commerce & Retail
  { url: 'Nitro.my', status: 'Active', owner: 'Own', group: 'Technology & SaaS', slogan: 'Seamless Embedded E-Commerce Solutions for Any Website.' },
  { url: 'CjIdeas-com.pages.dev', status: 'Inactive', owner: 'Client', group: 'E-commerce & Retail', slogan: 'Creative E-Commerce for Women, Girls, and Baby Essentials.' },
  { url: 'OZibala-com.pages.dev', status: 'Inactive', owner: 'Client', group: 'E-commerce & Retail', slogan: 'Your Online Marketplace for Everything You Need.' },
  { url: 'SwanCity-org.pages.dev', status: 'Inactive', owner: 'Client', group: 'Civic & Tourism', slogan: 'Marathons, Merchandise, and Civic Tourism in Full Swing.' },
  // Maritime & Shipping
  { url: 'AjanghShipping-com.pages.dev', status: 'Active', owner: 'Client', group: 'Maritime & Shipping', slogan: 'Reliable Logistics and Shipping Services Tailored to Your Needs.' },
  { url: 'Blue-Eng.com', status: 'Active', owner: 'Client', group: 'Maritime & Shipping', slogan: 'Expert Maritime Solutions for Shipyard Repairs and Maintenance.' },
  { url: 'JetMarine-com.pages.dev', status: 'Inactive', owner: 'Client', group: 'Maritime & Shipping', slogan: 'Specialists in High-Performance Shipping Propellers.' },
  // Education & Community
  { url: 'Sarawak.dev', status: 'Active', owner: 'Own', group: 'Nonprofit & Community', slogan: 'Driving Tech Education and Development in Sarawak.' },
  { url: 'StartupSarawak.com', status: 'Active', owner: 'Own', group: 'Nonprofit & Community', slogan: "Driving Innovation and Collaboration Among Sarawak's Startups." },
  { url: 'StartupSarawak.org', status: 'Active', owner: 'Own', group: 'Nonprofit & Community', slogan: 'Empowering Entrepreneurs with Resources and Support in Sarawak.' },
  // Sibu
  { url: 'Sibu.day', status: 'Active', owner: 'Own', group: 'Events', slogan: 'A Hub for Community Celebrations and Local Events in Sibu.' },
  { url: 'Sibu.dev', status: 'Active', owner: 'Own', group: 'Media & Content', slogan: 'Empowering Students with a Comprehensive Online Learning Platform.' },
  { url: 'SibuCity.com', status: 'Active', owner: 'Own', group: 'Civic & Tourism', slogan: 'Promoting the Charm and Culture of Sibu Through Tourism.' },
  { url: 'SibuTV-com.pages.dev', status: 'Inactive', owner: 'Client', group: 'Media & Content', slogan: "Capturing Sibu's Vibrant Events Through Video Content." },
  { url: 'SibuChat-com.pages.dev', status: 'Inactive', owner: 'Client', group: 'Media & Content', slogan: "Connecting Sibu's Influencers and Communities." },
  // Food & Beverage
  { url: 'Toast.my', status: 'Active', owner: 'Own', group: 'Food & Beverage', slogan: 'Delicious Sandwiches and Toasts from Your Local Kiosk.' },
  { url: 'TrinhCoffee-com.pages.dev', status: 'Active', owner: 'Client', group: 'Food & Beverage', slogan: 'Premium Coffee Bean Exports from Vietnam.' },
  { url: 'Blackball-com-sg.pages.dev', status: 'Inactive', owner: 'Client', group: 'Food & Beverage', slogan: "Serving Singapore's Favorite Bubble Tea Creations." },
  { url: 'ZDBgroup-my.pages.dev', status: 'Inactive', owner: 'Client', group: 'Food & Beverage', slogan: 'Serving Happiness with ZDBurger Group Delights.' },

  // Media & Entertainment
  { url: 'Lukis.app', status: 'Active', owner: 'Own', group: 'Storytelling', slogan: 'A Modern App for Drawing and Artistic Expression.' },
  { url: 'Tulis.my', status: 'Active', owner: 'Own', group: 'Storytelling', slogan: 'A Platform for Writers to Express and Share Their Creativity.' },
  { url: 'Chatnovel.app', status: 'Active', owner: 'Own', group: 'Storytelling', slogan: 'Craft Your Interactive Stories Through Chat-Based Storytelling.' },
  { url: 'Langnovel.com', status: 'Active', owner: 'Own', group: 'Storytelling', slogan: 'Building Frameworks for Dialogue-Driven Storytelling with LLM and LangChain.' },
  { url: 'Talknovel.com', status: 'Active', owner: 'Own', group: 'Storytelling', slogan: 'Transforming Stories into TTS-Enabled Audio Experiences.' },
  { url: 'Whatifnovel.com', status: 'Active', owner: 'Own', group: 'Storytelling', slogan: 'Explore Hypothetical Storytelling in Alternate Realities.' },
  { url: 'Hornbil-lit.my', status: 'Active', owner: 'Client', group: 'Storytelling', slogan: "Celebrating Sarawak's Literary Heritage and Creative Voices." },

  { url: 'Huruhara-com.pages.dev', status: 'Inactive', owner: 'Client', group: 'Media & Content', slogan: 'Nightlife Reimagined: Your Hub for Clubbing and Events Media.' },
  { url: 'Zenxer-com.pages.dev', status: 'Inactive', owner: 'Client', group: 'Media & Content', slogan: 'Innovating 3C Media Solutions for Modern Audiences.' },

  // Tourism & Hospitality & Agriculture
  { url: 'TycFarm-com.pages.dev', status: 'Inactive', owner: 'Client', group: 'Business', slogan: 'Sustainable Goat Farming in the Heart of Sarawak.' },
  { url: 'AjangHotel.com.my', status: 'Active', owner: 'Client', group: 'Business', slogan: 'Welcoming Guests with Comfort and Convenience in Malaysia.' },
  { url: 'Mission-Q.com', status: 'Active', owner: 'Client', group: 'Events', slogan: 'Immersive Escape Room and Gamified Experiences.' },
  { url: 'RajaFulsalMy-com.pages.dev', status: 'Inactive', owner: 'Client', group: 'Events', slogan: 'Effortless Management for Sports Tournaments and Competitions.' },

  // Technology & Software Development
  { url: 'Beam.Cards', status: 'Active', owner: 'Own', group: 'Technology & SaaS', slogan: 'Simplifying Connections Through Digital Business Cards.' },
  { url: 'JustCreate-site.pages.dev', status: 'Active', owner: 'Client', group: 'Technology & SaaS', slogan: 'Creating Websites Tailored for SMEs and Small Businesses.' },
  { url: 'AgroTracker-com.pages.dev', status: 'Inactive', owner: 'Client', group: 'Technology & SaaS', slogan: 'Revolutionizing Agriculture with Product QR Tracking Solutions.' },

  // Events & Social
  { url: 'II-my.pages.dev', status: 'Inactive', owner: 'Client', group: 'Events', slogan: 'Showcasing Love and Elegance at Wedding Exhibitions.' },
  { url: 'Syok.day', status: 'Active', owner: 'Own', group: 'Events', slogan: 'Celebrating Exciting Events Across Malaysia and Singapore.' },
  { url: 'Socially-my.pages.dev', status: 'Inactive', owner: 'Client', group: 'Events', slogan: 'Fostering Connections Through Speed Dating Events.' },

  // Business & Professional
  { url: 'SMEs-my.pages.dev', status: 'Inactive', owner: 'Client', group: 'Business', slogan: 'Empowering SMEs Through Media and Insights.' },
  { url: 'SECA-my.pages.dev', status: 'Inactive', owner: 'Client', group: 'Business', slogan: "Empowering Sarawak's E-Commerce Ecosystem." },

  // Automotive
  { url: 'ACar-my.pages.dev', status: 'Inactive', owner: 'Client', group: 'Automotive', slogan: 'Simplifying Vehicle Insurance Renewals with Ease.' },
  { url: 'CarEzy-my.pages.dev', status: 'Inactive', owner: 'Client', group: 'Automotive', slogan: 'Simplifying Car Trade for Modern Startups.' },
  { url: 'RemitEzy-com.pages.dev', status: 'Inactive', owner: 'Client', group: 'Business', slogan: 'Making Remittances Easy, Fast, and Reliable.' },
  { url: 'NBgroup-my.pages.dev', status: 'Inactive', owner: 'Client', group: 'Business', slogan: "United Ventures with Ngu's Brother Group." },

  // Misc
  { url: 'GraceMemorialPark-com.pages.dev', status: 'Active', owner: 'Client', group: 'Business', slogan: 'Honoring Memories with Dignity and Respect in Sarawak.' },
  { url: 'KrahGrandeBorneo-com.pages.dev', status: 'Active', owner: 'Client', group: 'Business', slogan: 'Building Excellence Through Innovative Construction Solutions.' },
  { url: 'KsMetal-com.my.pages.dev', status: 'Active', owner: 'Client', group: 'Business', slogan: 'Leading Metal Recycling Solutions for Environmental Sustainability.' },
  { url: 'Kts-foodland.com', status: 'Active', owner: 'Client', group: 'Food & Beverage', slogan: 'Your Premier Partner for Wholesale Food Distribution and Catering.' },
  { url: 'S-MM2H-com.pages.dev', status: 'Active', owner: 'Client', group: 'Business', slogan: 'Your Trusted Guide to Malaysia My Second Home Program.' },
  { url: 'IntoBorneo-com.pages.dev', status: 'Active', owner: 'Client', group: 'Civic & Tourism', slogan: 'Your Gateway to Authentic Borneo Adventures and Cultural Experiences.' },
  { url: 'YesMary-com.pages.dev', status: 'Active', owner: 'Client', group: 'Business', slogan: 'Premium Fashion Boutique for Modern Women.' },
  { url: 'PythonGuessBattle-com.pages.dev', status: 'Active', owner: 'Own', group: 'Technology & SaaS', slogan: 'Interactive AI-Powered Word Guessing Game.' },
];

const groups = Array.from(new Set(projects.map(project => project.group))).sort();
const owners = Array.from(new Set(projects.map(project => project.owner))).sort();
const statuses = Array.from(new Set(projects.map(project => project.status))).sort();

export function Projects() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [passcode, setPasscode] = useState('');
  const [error, setError] = useState('');
  const [selectedGroup, setSelectedGroup] = useState<string | null>(null);
  const [selectedOwner, setSelectedOwner] = useState<string | null>(null);
  const [selectedStatus, setSelectedStatus] = useState<string | null>(null);
  const [showFilters, setShowFilters] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const handlePasscodeSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (passcode.toLowerCase() === 'awesome') {
      setIsAuthenticated(true);
      // Store authentication in sessionStorage so it persists during the session
      sessionStorage.setItem('projectsAuthenticated', 'true');
    } else {
      setError('Incorrect passcode. Please try again.');
      setPasscode('');
    }
  };

  useEffect(() => {
    // Check if already authenticated in this session
    const authenticated = sessionStorage.getItem('projectsAuthenticated');
    if (authenticated === 'true') {
      setIsAuthenticated(true);
    }
  }, []);

  if (!isAuthenticated) {
    return (
      <section id="projects" className="py-20 scroll-mt-20">
        <div className="max-w-md mx-auto text-center">
          <h2 className="text-3xl font-bold tracking-tight mb-4">Project Portfolio</h2>
          <p className="text-muted-foreground mb-8">
            Please enter the passcode to view the projects
          </p>
          <form onSubmit={handlePasscodeSubmit} className="space-y-4">
            <div className="relative">
              <input
                type="password"
                value={passcode}
                onChange={(e) => setPasscode(e.target.value)}
                placeholder="Enter passcode"
                className="w-full pl-4 pr-4 py-2 rounded-full border border-input bg-background hover:bg-accent hover:text-accent-foreground"
              />
            </div>
            {error && (
              <p className="text-red-500 text-sm">{error}</p>
            )}
            <Button type="submit" className="w-full rounded-full">
              Submit
            </Button>
          </form>
        </div>
      </section>
    );
  }

  const filteredProjects = projects
    .sort((a, b) => a.url.localeCompare(b.url))
    .filter(project => 
      (!selectedGroup || project.group === selectedGroup) &&
      (!selectedOwner || project.owner === selectedOwner) &&
      (!selectedStatus || project.status === selectedStatus) &&
      (!searchTerm || 
        project.url.toLowerCase().includes(searchTerm.toLowerCase()) ||
        project.slogan.toLowerCase().includes(searchTerm.toLowerCase()) ||
        project.group.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );

  return (
    <section id="projects" className="py-20 scroll-mt-20">
      <div className="text-center mb-12">
        <h2 className="text-3xl font-bold tracking-tight mb-4">Project Portfolio</h2>
        <p className="text-muted-foreground max-w-[700px] mx-auto mb-6">
          Explore my diverse range of projects across various industries
        </p>
        <p className="text-sm text-muted-foreground max-w-[700px] mx-auto mb-6">
          Note: This portfolio excludes confidential projects protected by Non-Disclosure and Non-Compete agreements
        </p>
        
        <div className="relative max-w-md mx-auto">
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-muted-foreground h-4 w-4" />
          <input
            type="text"
            placeholder="Search projects..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full pl-10 pr-4 py-2 rounded-full border border-input bg-background hover:bg-accent hover:text-accent-foreground"
          />
        </div>
        {filteredProjects.length === 0 ? (
          <p className="text-muted-foreground mt-4">No projects found matching your criteria.</p>
        ) : (
          <p className="text-muted-foreground mt-4">
            Found {filteredProjects.length} project{filteredProjects.length === 1 ? '' : 's'}
          </p>
        )}
      </div>
      <div className="max-w-4xl mx-auto space-y-6 mb-12">
        <Button
          variant="outline"
          onClick={() => setShowFilters(!showFilters)}
          className="w-full flex items-center justify-between"
        >
          <span>Advanced Filters</span>
          <ChevronDown className={`h-4 w-4 transform transition-transform ${showFilters ? 'rotate-180' : ''}`} />
        </Button>

        {showFilters && (
          <div className="bg-card rounded-lg p-6 space-y-4 shadow-sm">
            <div className="space-y-2">
              <h3 className="font-medium text-sm text-muted-foreground">Filter by Owner</h3>
              <div className="flex flex-wrap gap-2">
                <Button
                  variant={selectedOwner === null ? "default" : "outline"}
                  onClick={() => setSelectedOwner(null)}
                  className="text-sm hover:scale-105 transition-transform"
                >
                  All Owners
                  <Badge variant="secondary" className="ml-2">
                    {projects.length}
                  </Badge>
                </Button>
                {owners.map((owner) => {
                  const count = projects.filter(p => p.owner === owner).length;
                  return (
                    <Button
                      key={owner}
                      variant={selectedOwner === owner ? "default" : "outline"}
                      onClick={() => setSelectedOwner(owner)}
                      className="text-sm hover:scale-105 transition-transform"
                    >
                      {owner}
                      <Badge variant="secondary" className="ml-2">
                        {count}
                      </Badge>
                    </Button>
                  );
                })}
              </div>
            </div>

            <div className="space-y-2">
              <h3 className="font-medium text-sm text-muted-foreground">Filter by Status</h3>
              <div className="flex flex-wrap gap-2">
                <Button
                  variant={selectedStatus === null ? "default" : "outline"}
                  onClick={() => setSelectedStatus(null)}
                  className="text-sm hover:scale-105 transition-transform"
                >
                  All Statuses
                  <Badge variant="secondary" className="ml-2">
                    {projects.length}
                  </Badge>
                </Button>
                {statuses.map((status) => {
                  const count = projects.filter(p => p.status === status).length;
                  return (
                    <Button
                      key={status}
                      variant={selectedStatus === status ? "default" : "outline"}
                      onClick={() => setSelectedStatus(status)}
                      className="text-sm hover:scale-105 transition-transform"
                    >
                      {status}
                      <Badge variant="secondary" className="ml-2">
                        {count}
                      </Badge>
                    </Button>
                  );
                })}
              </div>
            </div>

            <div className="space-y-2">
              <h3 className="font-medium text-sm text-muted-foreground">Filter by Group</h3>
              <div className="flex flex-wrap gap-2">
                <Button
                  variant={selectedGroup === null ? "default" : "outline"}
                  onClick={() => setSelectedGroup(null)}
                  className="text-sm hover:scale-105 transition-transform"
                >
                  All Groups
                  <Badge variant="secondary" className="ml-2">
                    {projects.length}
                  </Badge>
                </Button>
                {groups.map((group) => {
                  const count = projects.filter(p => p.group === group).length;
                  return (
                    <Button
                      key={group}
                      variant={selectedGroup === group ? "default" : "outline"}
                      onClick={() => setSelectedGroup(group)}
                      className="text-sm hover:scale-105 transition-transform"
                    >
                      {group}
                      <Badge variant="secondary" className="ml-2">
                        {count}
                      </Badge>
                    </Button>
                  );
                })}
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
        {filteredProjects.map((project) => (
          <Card key={project.url} className="flex flex-col p-6 hover:shadow-lg transition-all duration-300 border-2 hover:border-primary hover:scale-[1.02]">
            <div className="flex items-start justify-between gap-4">
              <div className="space-y-1">
                <div className="flex items-center gap-2">
                  <Globe className="h-4 w-4 text-primary" />
                  <h3 className="font-semibold">{project.url.replace('.pages.dev', '').replace(/-/g, '.')}</h3>
                </div>
                <div className="flex flex-wrap gap-2">
                <Badge 
                    className={`transition-colors ${project.status === 'Active' 
                      ? 'bg-green-100 text-green-800 hover:bg-green-200' 
                      : 'bg-gray-100 text-gray-800 hover:bg-gray-200'
                    }`}
                  >
                    {project.status}
                  </Badge>
                  <Badge variant="outline" className="hover:bg-primary hover:text-white transition-colors">
                    {project.owner}
                  </Badge>
                  <Badge variant="secondary" className="hover:bg-primary hover:text-white transition-colors">
                    {project.group}
                  </Badge>
                </div>
              </div>
            </div>
            <p className="mt-4 text-sm text-muted-foreground flex-grow">
              {project.slogan}
            </p>
            <div className="mt-6">
              <a
                href={`https://${project.url}`}
                target="_blank"
                rel="noopener noreferrer"
                className={`inline-flex items-center gap-2 px-4 py-2 rounded-md text-sm font-medium transition-all duration-300 hover:scale-105
                  ${project.status === 'Active' 
                    ? 'bg-green-100 text-green-800 hover:bg-green-200' 
                    : 'bg-gray-100 text-gray-800 hover:bg-gray-200'
                  }`}
              >
                {project.status === 'Active' ? (
                  <>
                    <Globe className="h-4 w-4" />
                    Live Site
                  </>
                ) : (
                  <>
                    <Archive className="h-4 w-4" />
                    View Archive
                  </>
                )}
              </a>
            </div>
          </Card>
        ))}
      </div>
    </section>
  );
}