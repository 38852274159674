import { Button } from '@/components/ui/button';
import { Card } from '@/components/ui/card';
import { Mail, Phone, MapPin, Github, Linkedin } from 'lucide-react';

export function Contact() {
  return (
    <section id="contact" className="py-20 scroll-mt-20">
      <div className="text-center mb-12">
        <h2 className="text-3xl font-bold tracking-tight mb-4">Let's Build Something Amazing</h2>
        <p className="text-muted-foreground max-w-[700px] mx-auto">
          Whether it's creating SaaS platforms, automating business workflows, or prototyping the next big idea, 
          I bring a wealth of experience and an innovative mindset to every project. Let's connect to explore how we can bring your vision to life.
        </p>
      </div>
      <Card className="p-6 max-w-3xl mx-auto">
        <div className="grid gap-6 md:grid-cols-2">
          <div className="space-y-4">
            <h3 className="font-semibold text-xl mb-6">Contact Information</h3>
            <Button variant="outline" className="w-full justify-start" asChild>
              <a href="mailto:career@tanghoong.com">
                <Mail className="mr-2 h-4 w-4" />
                career@tanghoong.com
              </a>
            </Button>
            <Button variant="outline" className="w-full justify-start" asChild>
              <a href="https://wa.me/601156239249">
                <Phone className="mr-2 h-4 w-4" />
                +6011-56-239-249
              </a>
            </Button>
            <Button variant="outline" className="w-full justify-start">
              <MapPin className="mr-2 h-4 w-4" />
              Sarawak, Malaysia
            </Button>
          </div>
          <div className="space-y-4">
            <h3 className="font-semibold text-xl mb-6">Social Links</h3>
            <Button variant="outline" className="w-full justify-start" asChild>
              <a href="https://github.com/tanghoong" target="_blank" rel="noopener noreferrer">
                <Github className="mr-2 h-4 w-4" />
                GitHub Profile
              </a>
            </Button>
            <Button variant="outline" className="w-full justify-start" asChild>
              <a href="https://www.linkedin.com/in/tanghoong/" target="_blank" rel="noopener noreferrer">
                <Linkedin className="mr-2 h-4 w-4" />
                LinkedIn Profile
              </a>
            </Button>
          </div>
        </div>
      </Card>
    </section>
  );
}